<template>
  <span class="icon align-middle" :class="[`icon-${props.icon}`, `text-${props.size}`, { 'p-2 rounded-full text-neutral-50 bg-primary-500': props.filled }, props.class]">
    <template v-for="index in (multiPathIcons[props.icon] ?? 0)">
      <span :class="`path${index}`"></span>
    </template>
  </span>
</template>
<script setup>

const props = defineProps({
  icon: String,
  filled: Boolean,
  class: String,
  size: String
});

const multiPathIcons = {
  'cs': 3,
  'sk': 7,
  'en': 5
}

</script>